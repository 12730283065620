import { Component, FormEvent, FunctionComponent } from "react";
import {
    Form,
    Alert,
    Card,
    Col,
    Row,
    ListGroupItem,
    ListGroup,
} from "react-bootstrap";
import { Auth } from "aws-amplify";
import { RouteComponentProps } from "react-router-dom";
import { Color, Path, Storage } from "../../env";
import {
    IUpdateDeliveryDate,
    DeliveryDate as DeliveryDateDto,
    Day,
    OrderDate,
    Timerange as TimerangeDTO,
} from "../../client/core";
import { FaTrashAlt, FaVrCardboard } from "react-icons/fa";
import { Button, icon, variant } from "../form/Button";

export interface DeliveryDateState {
    timerange: Map<Day, TimerangeDTO>;
    orderBefore: Map<Day, OrderDate>;
    dates: Map<Day, DeliveryDateDto>;
    action: Action;
    error: string;
}

export enum Action {
    Update,
    View,
}

export interface DeliveryDateProps extends RouteComponentProps {
    dates: Map<Day, DeliveryDateDto>;
    auth: typeof Auth;
    updateDeliveryDateAPI: IUpdateDeliveryDate;
}

export default class DeliveryDate extends Component<
    DeliveryDateProps,
    DeliveryDateState
> {
    constructor(props: DeliveryDateProps) {
        super(props);
        this.state = {
            timerange: new Map(),
            orderBefore: new Map(),
            dates: new Map(),
            action: Action.View,
            error: "",
        };
    }

    handleNavigate = (path: Path) => {
        this.props.history.push(path);
    };

    componentDidUpdate() {
        if (
            (!this.state.dates && !this.props.dates) ||
            (this.state.dates && this.state.dates.size !== 0) ||
            (this.props.dates && this.props.dates.size === 0)
        ) {
            return;
        }
        this.setState({
            dates: this.props.dates,
        });
    }

    handleSubmit = async () => {
        const token = await this.getToken();
        if (token instanceof Error) {
            await this.props.auth.signOut();
            return this.props.history.push(Path.Login);
        }
        const shopID = localStorage.getItem(Storage.ShopID);
        const response =
            await this.props.updateDeliveryDateAPI.UpdateDeliveryDate({
                dates: this.marshalDates(this.state.dates),
                identityToken: token,
                shopID: shopID!,
            });
        if (response instanceof Error) {
            return this.setState({
                error: "We were unable to process the update delivery request. Please try again later.",
            });
        }

        if (response.statusCode === 403) {
            return this.setState({
                error: "You appear not to have authorisation to modify the delivery date. Please navigate to the Shop dashboard to resync.",
            });
        }

        if (response.statusCode !== 201) {
            return this.setState({
                error: "We were unable to process the update delivery request. Please try again later.",
            });
        }

        this.setState({
            action: Action.View,
        });
    };

    marshalDates = (dates: Map<Day, DeliveryDateDto>): DeliveryDateDto[] => {
        const response: DeliveryDateDto[] = [];
        dates.forEach((date) => response.push(date));
        return response;
    };

    getToken = async (): Promise<string | Error> => {
        try {
            const session = await this.props.auth.currentSession();
            return session.getIdToken().getJwtToken();
        } catch {
            return new Error(
                "Failed to retrieve user session. Relogin is required."
            );
        }
    };

    handleTimerangeDelete = (day: Day, key: number): void => {
        const { dates } = this.state;
        if (!dates.has(day)) {
            return;
        }
        const currentDay = dates.get(day)!;
        currentDay.timeranges = currentDay.timeranges.filter(
            (timerange, index) => key !== index
        );
        dates.set(day, currentDay);
        this.setState({
            dates: dates,
        });
    };

    handleAddDayTime = (day: Day, timerange: TimerangeDTO): void => {
        const { dates } = this.state;
        if (!dates.has(day)) {
            dates.set(day, {
                day: day,
                timeranges: [],
            });
        }
        if (!dates.get(day)?.timeranges) {
            dates.get(day)!.timeranges = []
        }
        dates.get(day)!.timeranges.push(timerange);
        this.setState({
            dates: dates,
        });
    };

    handleTimerangeChange = (day: Day, start: string, end: string): void => {
        const { timerange } = this.state;
        timerange.set(day, {
            start: start,
            end: end,
        });
        this.setState({
            timerange: timerange,
        });
    };

    handleOrderBeforeDayChange = (day: Day, orderBeforeDay: Day): void => {
        const { orderBefore } = this.state;
        let update: OrderDate = orderBefore.has(day)
            ? orderBefore.get(day)!
            : {
                day: "Monday",
                time: "00:00",
            };
        update.day = orderBeforeDay;
        orderBefore.set(day, update);
        this.setState({
            orderBefore: orderBefore,
        });
    };

    handleOrderBeforeTimeChange = (day: Day, orderBeforeTime: string): void => {
        const { orderBefore } = this.state;
        let update: OrderDate = orderBefore.has(day)
            ? orderBefore.get(day)!
            : {
                day: day,
                time: "00:00",
            };
        update.time = orderBeforeTime;
        orderBefore.set(day, update);
        this.setState({
            orderBefore: orderBefore,
        });
    };

    handleUpdateOrderBefore = (day: Day, orderBefore: OrderDate): void => {
        let { dates } = this.state;
        if (!dates) {
            dates = new Map();
        }
        if (!dates.has(day)) {
            dates.set(day, {
                day: day,
                timeranges: [],
            });
        }
        dates.get(day)!.orderBefore = orderBefore;
        this.setState({
            dates: dates,
        });
    };

    changeAction = (action: Action) => {
        this.setState({
            action: action,
        });
    };

    render() {
        const deliveryRadiusViewProps: DeliveryDateViewProps = {
            error: this.state.error,
            dates:
                this.state.dates && this.state.dates.size > 0
                    ? this.state.dates
                    : this.props.dates,
            currentTimerange: this.state.timerange,
            currentOrderBefore: this.state.orderBefore,
            changeAction: this.changeAction,
            onSubmit: this.handleSubmit,
            onNavigate: this.handleNavigate,
            onAddDayTime: this.handleAddDayTime,
            onTimerangeChange: this.handleTimerangeChange,
            onTimerangeDelete: this.handleTimerangeDelete,
            onOrderBeforeDayChange: this.handleOrderBeforeDayChange,
            onOrderBeforeTimeChange: this.handleOrderBeforeTimeChange,
            onOrderBeforeUpdate: this.handleUpdateOrderBefore,
        };
        return (
            <>
                {this.state.action === Action.Update && (
                    <UpdateDeliveryDateForm {...deliveryRadiusViewProps} />
                )}
                {this.state.action === Action.View && (
                    <ViewDeliveryDate {...deliveryRadiusViewProps} />
                )}
            </>
        );
    }
}

export interface DeliveryDateViewProps {
    error: string;
    dates: Map<Day, DeliveryDateDto>;
    currentTimerange: Map<Day, TimerangeDTO>;
    currentOrderBefore: Map<Day, OrderDate>;
    changeAction: (action: Action) => void;
    onAddDayTime: (day: Day, timerange: TimerangeDTO) => void;
    onTimerangeChange: (day: Day, start: string, end: string) => void;
    onOrderBeforeTimeChange: (day: Day, orderBeforeTime: string) => void;
    onOrderBeforeDayChange: (day: Day, orderBeforeDay: Day) => void;
    onOrderBeforeUpdate: (day: Day, orderBefore: OrderDate) => void;
    onNavigate: (path: Path) => void;
    onTimerangeDelete: (day: Day, key: number) => void;
    onSubmit: () => void;
}

export const UpdateDeliveryDateForm: FunctionComponent<
    DeliveryDateViewProps
> = (props) => (
    <Card>
        <Card.Body>

            <Form onSubmit={props.onSubmit}>
                <Alert variant={"danger"} show={props.error != ""}>
                    {props.error}
                </Alert>
                <Row>
                    <Col md={9}>
                        <Card.Title
                            style={{
                                paddingBottom: ".3rem",
                            }}
                        >
                            Delivery Dates
                        </Card.Title>
                        <Card.Subtitle
                            style={{
                                paddingBottom: ".5rem",
                            }}
                        >
                            Set recurring delivery dates and timeranges.
                        </Card.Subtitle>
                    </Col>
                    <Col md={3} style={{ "textAlign": "right" }}>
                        <Button
                            name={"Cancel"}
                            variant={variant.Secondary}
                            onClick={() => props.changeAction(Action.View)}
                        />
                        <Button
                            variant={variant.Primary}
                            name={"Submit"}
                            onClick={() => props.onSubmit()}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>
                            Add time ranges to the days in which you can
                            delivery:
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DateDay
                            day={"Monday"}
                            timeranges={
                                props.dates.has("Monday")
                                    ? props.dates.get("Monday")!.timeranges
                                    : []
                            }
                            orderBefore={
                                props.dates.has("Monday")
                                    ? props.dates.get("Monday")!.orderBefore
                                    : undefined
                            }
                            onAdd={props.onAddDayTime}
                            onChange={(start, end) =>
                                props.onTimerangeChange("Monday", start, end)
                            }
                            currentTimerange={
                                props.currentTimerange.has("Monday")
                                    ? props.currentTimerange.get("Monday")!
                                    : {
                                        start: "00:00",
                                        end: "00:00",
                                    }
                            }
                            onDelete={props.onTimerangeDelete}
                            currentOrderBefore={
                                props.currentOrderBefore.has("Monday")
                                    ? props.currentOrderBefore.get("Monday")!
                                    : {
                                        day: "Monday",
                                        time: "0:00",
                                    }
                            }
                            onOrderBeforeDayChange={
                                props.onOrderBeforeDayChange
                            }
                            onOrderBeforeTimeChange={
                                props.onOrderBeforeTimeChange
                            }
                            onOrderBeforeSubmit={props.onOrderBeforeUpdate}
                        />
                        <DateDay
                            day={"Tuesday"}
                            timeranges={
                                props.dates.has("Tuesday")
                                    ? props.dates.get("Tuesday")!.timeranges
                                    : []
                            }
                            orderBefore={
                                props.dates.has("Tuesday")
                                    ? props.dates.get("Tuesday")!.orderBefore
                                    : undefined
                            }
                            onAdd={props.onAddDayTime}
                            onChange={(start, end) =>
                                props.onTimerangeChange("Tuesday", start, end)
                            }
                            currentTimerange={
                                props.currentTimerange.has("Tuesday")
                                    ? props.currentTimerange.get("Tuesday")!
                                    : {
                                        start: "00:00",
                                        end: "00:00",
                                    }
                            }
                            onDelete={props.onTimerangeDelete}
                            currentOrderBefore={
                                props.currentOrderBefore.has("Tuesday")
                                    ? props.currentOrderBefore.get("Tuesday")!
                                    : {
                                        day: "Tuesday",
                                        time: "0:00",
                                    }
                            }
                            onOrderBeforeDayChange={
                                props.onOrderBeforeDayChange
                            }
                            onOrderBeforeTimeChange={
                                props.onOrderBeforeTimeChange
                            }
                            onOrderBeforeSubmit={props.onOrderBeforeUpdate}
                        />
                        <DateDay
                            day={"Wednesday"}
                            timeranges={
                                props.dates.has("Wednesday")
                                    ? props.dates.get("Wednesday")!.timeranges
                                    : []
                            }
                            orderBefore={
                                props.dates.has("Wednesday")
                                    ? props.dates.get("Wednesday")!.orderBefore
                                    : undefined
                            }
                            onAdd={props.onAddDayTime}
                            onChange={(start, end) =>
                                props.onTimerangeChange("Wednesday", start, end)
                            }
                            currentTimerange={
                                props.currentTimerange.has("Wednesday")
                                    ? props.currentTimerange.get("Wednesday")!
                                    : {
                                        start: "00:00",
                                        end: "00:00",
                                    }
                            }
                            onDelete={props.onTimerangeDelete}
                            currentOrderBefore={
                                props.currentOrderBefore.has("Wednesday")
                                    ? props.currentOrderBefore.get("Wednesday")!
                                    : {
                                        day: "Wednesday",
                                        time: "0:00",
                                    }
                            }
                            onOrderBeforeDayChange={
                                props.onOrderBeforeDayChange
                            }
                            onOrderBeforeTimeChange={
                                props.onOrderBeforeTimeChange
                            }
                            onOrderBeforeSubmit={props.onOrderBeforeUpdate}
                        />
                        <DateDay
                            day={"Thursday"}
                            timeranges={
                                props.dates.has("Thursday")
                                    ? props.dates.get("Thursday")!.timeranges
                                    : []
                            }
                            orderBefore={
                                props.dates.has("Thursday")
                                    ? props.dates.get("Thursday")!.orderBefore
                                    : undefined
                            }
                            onAdd={props.onAddDayTime}
                            onChange={(start, end) =>
                                props.onTimerangeChange("Thursday", start, end)
                            }
                            currentTimerange={
                                props.currentTimerange.has("Thursday")
                                    ? props.currentTimerange.get("Thursday")!
                                    : {
                                        start: "00:00",
                                        end: "00:00",
                                    }
                            }
                            onDelete={props.onTimerangeDelete}
                            currentOrderBefore={
                                props.currentOrderBefore.has("Thursday")
                                    ? props.currentOrderBefore.get("Thursday")!
                                    : {
                                        day: "Thursday",
                                        time: "0:00",
                                    }
                            }
                            onOrderBeforeDayChange={
                                props.onOrderBeforeDayChange
                            }
                            onOrderBeforeTimeChange={
                                props.onOrderBeforeTimeChange
                            }
                            onOrderBeforeSubmit={props.onOrderBeforeUpdate}
                        />
                        <DateDay
                            day={"Friday"}
                            timeranges={
                                props.dates.has("Friday")
                                    ? props.dates.get("Friday")!.timeranges
                                    : []
                            }
                            orderBefore={
                                props.dates.has("Friday")
                                    ? props.dates.get("Friday")!.orderBefore
                                    : undefined
                            }
                            onAdd={props.onAddDayTime}
                            onChange={(start, end) =>
                                props.onTimerangeChange("Friday", start, end)
                            }
                            currentTimerange={
                                props.currentTimerange.has("Friday")
                                    ? props.currentTimerange.get("Friday")!
                                    : {
                                        start: "00:00",
                                        end: "00:00",
                                    }
                            }
                            onDelete={props.onTimerangeDelete}
                            currentOrderBefore={
                                props.currentOrderBefore.has("Friday")
                                    ? props.currentOrderBefore.get("Friday")!
                                    : {
                                        day: "Friday",
                                        time: "0:00",
                                    }
                            }
                            onOrderBeforeDayChange={
                                props.onOrderBeforeDayChange
                            }
                            onOrderBeforeTimeChange={
                                props.onOrderBeforeTimeChange
                            }
                            onOrderBeforeSubmit={props.onOrderBeforeUpdate}
                        />
                        <DateDay
                            day={"Saturday"}
                            timeranges={
                                props.dates.has("Saturday")
                                    ? props.dates.get("Saturday")!.timeranges
                                    : []
                            }
                            orderBefore={
                                props.dates.has("Saturday")
                                    ? props.dates.get("Saturday")!.orderBefore
                                    : undefined
                            }
                            onAdd={props.onAddDayTime}
                            onChange={(start, end) =>
                                props.onTimerangeChange("Saturday", start, end)
                            }
                            currentTimerange={
                                props.currentTimerange.has("Saturday")
                                    ? props.currentTimerange.get("Saturday")!
                                    : {
                                        start: "00:00",
                                        end: "00:00",
                                    }
                            }
                            onDelete={props.onTimerangeDelete}
                            currentOrderBefore={
                                props.currentOrderBefore.has("Saturday")
                                    ? props.currentOrderBefore.get("Saturday")!
                                    : {
                                        day: "Saturday",
                                        time: "0:00",
                                    }
                            }
                            onOrderBeforeDayChange={
                                props.onOrderBeforeDayChange
                            }
                            onOrderBeforeTimeChange={
                                props.onOrderBeforeTimeChange
                            }
                            onOrderBeforeSubmit={props.onOrderBeforeUpdate}
                        />
                        <DateDay
                            day={"Sunday"}
                            timeranges={
                                props.dates.has("Sunday")
                                    ? props.dates.get("Sunday")!.timeranges
                                    : []
                            }
                            orderBefore={
                                props.dates.has("Sunday")
                                    ? props.dates.get("Sunday")!.orderBefore
                                    : undefined
                            }
                            onAdd={props.onAddDayTime}
                            onChange={(start, end) =>
                                props.onTimerangeChange("Sunday", start, end)
                            }
                            currentTimerange={
                                props.currentTimerange.has("Sunday")
                                    ? props.currentTimerange.get("Sunday")!
                                    : {
                                        start: "00:00",
                                        end: "00:00",
                                    }
                            }
                            onDelete={props.onTimerangeDelete}
                            currentOrderBefore={
                                props.currentOrderBefore.has("Sunday")
                                    ? props.currentOrderBefore.get("Sunday")!
                                    : {
                                        day: "Sunday",
                                        time: "0:00",
                                    }
                            }
                            onOrderBeforeDayChange={
                                props.onOrderBeforeDayChange
                            }
                            onOrderBeforeTimeChange={
                                props.onOrderBeforeTimeChange
                            }
                            onOrderBeforeSubmit={props.onOrderBeforeUpdate}
                        />
                    </Col>
                </Row>

            </Form>
        </Card.Body>
    </Card>
);

export interface DateDayProps {
    day: Day;
    timeranges: TimerangeDTO[];
    orderBefore?: OrderDate;
    currentTimerange: TimerangeDTO;
    currentOrderBefore: OrderDate;
    onAdd: (day: Day, timerange: TimerangeDTO) => void;
    onChange: (start: string, end: string) => void;
    onDelete: (day: Day, key: number) => void;
    onOrderBeforeTimeChange: (day: Day, orderBeforeTime: string) => void;
    onOrderBeforeDayChange: (day: Day, orderBeforeDay: Day) => void;
    onOrderBeforeSubmit: (day: Day, orderBefore: OrderDate) => void;
}

export const DateDay: FunctionComponent<DateDayProps> = (props) => (
    <div style={{ marginBottom: "1rem" }}>
        <Card>
            <Card.Body>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <p
                                    style={{
                                        fontWeight: "bold",
                                    }}
                                >
                                    {props.day}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {props.orderBefore && (
                                    <Card>
                                        <Card.Body>
                                            <p>Day: {props.orderBefore?.day}</p>
                                            <p>
                                                Time: {props.orderBefore?.time}
                                            </p>
                                        </Card.Body>
                                    </Card>
                                )}
                            </Col>
                            <Col>
                                <UpdateTimeranges
                                    timeranges={props.timeranges}
                                    onDelete={(key) =>
                                        props.onDelete(props.day, key)
                                    }
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "1rem" }}>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Form
                                            onSubmit={(e) => {}}
                                        >
                                            <Row>
                                                <Col>
                                                    <p
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Orders Before
                                                    </p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Card>
                                                        <Card.Body
                                                            style={{
                                                                padding:
                                                                    ".5rem",
                                                            }}
                                                        >
                                                            <Form.Select
                                                                style={{
                                                                    width: "100%",
                                                                    background:
                                                                        "white",
                                                                    border: 0,
                                                                }}
                                                                onChange={(e) =>
                                                                    props.onOrderBeforeDayChange(
                                                                        props.day,
                                                                        e.target
                                                                            .value as Day
                                                                    )
                                                                }
                                                            >
                                                                <option>
                                                                    Select Day
                                                                </option>
                                                                <option value="Monday">
                                                                    Monday
                                                                </option>
                                                                <option value="Tuesday">
                                                                    Tuesday
                                                                </option>
                                                                <option value="Wednesday">
                                                                    Wednesday
                                                                </option>
                                                                <option value="Thursday">
                                                                    Thursday
                                                                </option>
                                                                <option value="Friday">
                                                                    Friday
                                                                </option>
                                                                <option value="Saturday">
                                                                    Saturday
                                                                </option>
                                                                <option value="Sunday">
                                                                    Sunday
                                                                </option>
                                                            </Form.Select>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    <Form.Control
                                                        value={
                                                            props
                                                                .currentOrderBefore
                                                                .time
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Range
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        onChange={(e) =>
                                                            props.onOrderBeforeTimeChange(
                                                                props.day,
                                                                TimerangeMap.get(
                                                                    e.target
                                                                        .value
                                                                )!
                                                            )
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: "1rem" }}>
                                                <Col>
                                                    <Button
                                                        name={"Update"}
                                                        variant={variant.Primary}
                                                        onClick={() =>
                                                            props.onOrderBeforeSubmit(
                                                                props.day,
                                                                props.currentOrderBefore
                                                            )
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <UpdateTimerange
                                    onChange={(start, end) =>
                                        props.onChange(start, end)
                                    }
                                    onAdd={(timerange) =>
                                        props.onAdd(props.day, timerange)
                                    }
                                    start={props.currentTimerange.start}
                                    end={props.currentTimerange.end}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col sm={9} md={10} lg={11}></Col>
                    <Col xs={12} sm={3} md={2} lg={1}></Col>
                </Row>
            </Card.Body>
        </Card>
    </div>
);

const TimerangeMap: Map<string, string> = new Map([
    ["1", "0:00"],
    ["2", "00:00"],
    ["3", "00:30"],
    ["4", "01:00"],
    ["5", "01:30"],
    ["6", "02:00"],
    ["7", "02:30"],
    ["8", "03:00"],
    ["9", "03:30"],
    ["10", "04:00"],
    ["11", "04:30"],
    ["12", "05:00"],
    ["13", "05:15"],
    ["14", "05:30"],
    ["15", "05:45"],
    ["16", "06:00"],
    ["17", "06:15"],
    ["18", "06:30"],
    ["19", "06:45"],
    ["20", "07:00"],
    ["21", "07:15"],
    ["22", "07:30"],
    ["23", "07:45"],
    ["24", "08:00"],
    ["25", "08:15"],
    ["26", "08:30"],
    ["27", "08:45"],
    ["28", "09:00"],
    ["29", "09:15"],
    ["30", "09:30"],
    ["31", "09:45"],
    ["32", "10:00"],
    ["33", "10:15"],
    ["34", "10:30"],
    ["35", "10:45"],
    ["36", "11:00"],
    ["37", "11:15"],
    ["38", "11:30"],
    ["39", "11:45"],
    ["40", "12:00"],
    ["50", "12:15"],
    ["51", "12:30"],
    ["52", "12:45"],
    ["53", "13:00"],
    ["54", "13:15"],
    ["55", "13:30"],
    ["56", "13:45"],
    ["57", "14:00"],
    ["58", "14:15"],
    ["59", "14:30"],
    ["60", "14:45"],
    ["61", "15:00"],
    ["62", "15:15"],
    ["63", "15:30"],
    ["64", "15:45"],
    ["65", "16:00"],
    ["66", "16:15"],
    ["67", "16:30"],
    ["68", "16:45"],
    ["69", "17:00"],
    ["70", "17:15"],
    ["71", "17:30"],
    ["72", "17:45"],
    ["73", "18:00"],
    ["74", "18:15"],
    ["75", "18:30"],
    ["76", "18:45"],
    ["77", "19:00"],
    ["78", "19:15"],
    ["79", "19:30"],
    ["80", "19:45"],
    ["81", "19:00"],
    ["82", "19:15"],
    ["83", "19:30"],
    ["84", "19:45"],
    ["85", "20:00"],
    ["86", "20:15"],
    ["87", "20:30"],
    ["88", "20:45"],
    ["89", "21:00"],
    ["90", "21:15"],
    ["91", "21:30"],
    ["92", "21:45"],
    ["93", "22:00"],
    ["94", "22:15"],
    ["95", "22:30"],
    ["96", "22:45"],
    ["97", "23:00"],
    ["98", "23:15"],
    ["99", "23:30"],
    ["100", "23:45"],
]);

export interface ViewTimerangesProps {
    timeranges: TimerangeDTO[];
    onDelete: (key: number) => void;
}

export const ViewTimeranges: FunctionComponent<ViewTimerangesProps> = (
    props
) => (
    <ListGroup>
        {props.timeranges.map((timerange, index) => (
            <ListGroupItem>
                From: {timerange.start} - Till:{timerange.end}
            </ListGroupItem>
        ))}
    </ListGroup>
);

export interface UpdateTimerangesProps {
    timeranges: TimerangeDTO[];
    onDelete: (key: number) => void;
}

export const UpdateTimeranges: FunctionComponent<UpdateTimerangesProps> = (
    props
) => (
    <ListGroup>
        {props.timeranges && props.timeranges.map((timerange, index) => (
            <ListGroupItem>
                From: {timerange.start} - Till:{timerange.end}
                <Button
                    style={{ float: "right" }}
                    icon={icon.Trash}
                    onClick={() => props.onDelete(index)}
                    variant={variant.Danger}
                >
                    <FaTrashAlt />
                </Button>
            </ListGroupItem>
        ))}
    </ListGroup>
);

export interface UpdateTimerangeProps {
    start: string;
    end: string;
    onChange: (start: string, end: string) => void;
    onAdd: (timerange: TimerangeDTO) => void;
}

export const UpdateTimerange: FunctionComponent<UpdateTimerangeProps> = (
    props
) => (
    <div>
        <Row>
            <Col>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col>
                                <p
                                    style={{
                                        fontWeight: "bold",
                                    }}
                                >
                                    Add Timerange
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <Form.Range
                                            style={{ width: "100%" }}
                                            onChange={(e) =>
                                                props.onChange(
                                                    TimerangeMap.get(
                                                        e.target.value
                                                    )!,
                                                    props.end
                                                )
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col
                                        xs={6}
                                        style={{
                                            textAlign: "center",
                                        }}
                                    >
                                        Start
                                    </Col>
                                    <Col xs={6}></Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                <Form.Control value={props.start} />
                            </Col>
                            <Col
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                <Form.Control value={props.end} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col xs={6}></Col>
                                    <Col
                                        xs={6}
                                        style={{
                                            textAlign: "center",
                                        }}
                                    >
                                        End
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Range
                                            onChange={(e) =>
                                                props.onChange(
                                                    props.start,
                                                    TimerangeMap.get(
                                                        e.target.value
                                                    )!
                                                )
                                            }
                                            style={{ width: "100%" }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Button
                            variant={variant.Primary}
                            name="Add"
                            onClick={() =>
                                props.onAdd({
                                    start: props.start,
                                    end: props.end,
                                })
                            }
                        />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </div>
);

export const ViewDeliveryDate: FunctionComponent<DeliveryDateViewProps> = (
    props
) => (
    <div>
        <Card>
            <Card.Body>
                <Row>
                    <Col xs={12} sm={10}>
                        <Card.Title
                            style={{
                                paddingBottom: ".3rem",
                            }}
                        >
                            Delivery Dates
                        </Card.Title>
                        <Card.Subtitle
                            style={{
                                paddingBottom: ".5rem",
                            }}
                        >
                            Set recurring delivery dates and timeranges.
                        </Card.Subtitle>
                        <Row>
                            <Col>
                                <DeliveryDateElementView
                                    onDelete={(key) =>
                                        props.onTimerangeDelete("Monday", key)
                                    }
                                    date={props.dates.get("Monday")}
                                />
                                <DeliveryDateElementView
                                    onDelete={(key) =>
                                        props.onTimerangeDelete("Tuesday", key)
                                    }
                                    date={props.dates.get("Tuesday")}
                                />
                                <DeliveryDateElementView
                                    onDelete={(key) =>
                                        props.onTimerangeDelete(
                                            "Wednesday",
                                            key
                                        )
                                    }
                                    date={props.dates.get("Wednesday")}
                                />
                                <DeliveryDateElementView
                                    onDelete={(key) =>
                                        props.onTimerangeDelete("Thursday", key)
                                    }
                                    date={props.dates.get("Thursday")}
                                />
                                <DeliveryDateElementView
                                    onDelete={(key) =>
                                        props.onTimerangeDelete("Friday", key)
                                    }
                                    date={props.dates.get("Friday")}
                                />
                                <DeliveryDateElementView
                                    onDelete={(key) =>
                                        props.onTimerangeDelete("Saturday", key)
                                    }
                                    date={props.dates.get("Saturday")}
                                />
                                <DeliveryDateElementView
                                    onDelete={(key) =>
                                        props.onTimerangeDelete("Sunday", key)
                                    }
                                    date={props.dates.get("Sunday")}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} sm={2}>
                        <Button
                            variant={variant.Primary}
                            name="Edit"
                            style={{ float: "right" }}
                            onClick={() => props.changeAction(Action.Update)}
                        />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    </div>
);

export interface DeliveryDateElementProps {
    date?: DeliveryDateDto;
    onDelete: (key: number) => void;
}

export const DeliveryDateElementView: FunctionComponent<
    DeliveryDateElementProps
> = (props) => (
    <span>
        {props.date && props.date.timeranges && props.date.timeranges.length > 0 && (
            <Card style={{ marginBottom: ".5rem" }}>
                <Card.Body>
                    <p>{props.date.day}</p>
                    <Row>
                        <Col>
                            <p>Order Before</p>
                            <Card>
                                <Card.Body>
                                    <p>Day: {props.date.orderBefore?.day}</p>
                                    <p>Time: {props.date.orderBefore?.time}</p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <p>Delivery Timeranges</p>
                            <ViewTimeranges
                                onDelete={props.onDelete}
                                timeranges={props.date?.timeranges}
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        )}
    </span>
);

import { Auth } from "aws-amplify";
import { Component, FunctionComponent } from "react";
import {
    Alert,
    Card,
    Col,
    Image,
    ListGroup,
    Modal,
    Row,
    Spinner,
} from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { ContactDetails, IGetShop, IRegisterAccount, SetShopContactDetails, ShopAction, ShopSummary, UpdateShopAlias } from "../../client/core";
import { Color, Path, Storage } from "../../env";
import { Button, variant } from "../form/Button";
import UpdateLogo from "../account/UpdateLogo";
import { Storage as S3 } from "aws-amplify";
import UpdateAlias from "../account/UpdateAlias";
import UpdateContact from "../account/UpdateContact";

export interface ShopDashboardState {
    error: string;
    shopSummary: ShopSummary;
    isLoading: boolean;
    activity: Activity;
}

export interface ShopDashboardProps extends RouteComponentProps {
    getShopAPI: IGetShop;
    registerShopAPI: IRegisterAccount;
    auth: typeof Auth;
}

export default class ShopDashboard extends Component<
    ShopDashboardProps,
    ShopDashboardState
> {
    constructor(props: ShopDashboardProps) {
        super(props);
        this.state = {
            shopSummary: {
                name: "",
                address: "",
                accountStatus: "",
                deliveryStatus: "",
                credit: 0,
                logo: "",
                alias: "",
                color: "",
                email: "",
                phone: "",
                actions: [],
            },
            isLoading: true,
            error: "",
            activity: Activity.None,
        };
    }

    componentDidMount() {
        this.getShopSummary();
    }

    logOut = () => {
        localStorage.removeItem(Storage.ShopID);
        this.props.auth.signOut();
        localStorage.removeItem(Storage.IsLoggedIn);
        return this.props.history.push(Path.Login);
    };

    getShopSummary = () => {
        if (!localStorage.getItem(Storage.IsLoggedIn)) {
            return this.props.history.push(Path.Login);
        }

        this.props.auth.currentSession().then(async (session) => {
            let shopID = localStorage.getItem(Storage.ShopID);

            // if shopID is not present retrieve from API
            if (shopID == null) {
                const token = session.getIdToken().getJwtToken();
                await this.props.getShopAPI
                    .GetShopID({
                        identityToken: token,
                    })
                    .then((output) => {
                        if (output.statusCode === 403) {
                            return this.logOut();
                        }
                        if (output.statusCode === 404) {
                            localStorage.removeItem(Storage.ShopID)
                            return this.props.history.push(Path.RegisterShop);
                        }
                        if (output.statusCode !== 200) {
                            this.setState({
                                error: "Sorry, we were unable to find your shop details",
                            });
                        }
                        localStorage.setItem(Storage.ShopID, output.shopID!);
                    })
                    .catch((err) =>
                        this.setState({
                            error: err.message,
                        })
                    );
                shopID = localStorage.getItem(Storage.ShopID);
                if (shopID == null) {
                    return;
                }
            }

            const token = session.getIdToken().getJwtToken();
            this.props.getShopAPI
                .GetShopSummary({
                    identityToken: token,
                    shopID: shopID!,
                    addressID: localStorage.getItem(Storage.ShopAddressID)
                })
                .then((output) => {
                    if (output.statusCode !== 200) {
                        localStorage.removeItem(Storage.ShopID);
                        this.props.auth.signOut();
                        localStorage.removeItem(Storage.IsLoggedIn);
                        return this.props.history.push(Path.Login);
                    }
                    localStorage.setItem(Storage.ShopName, output.shopSummary!.name)
                    this.setState({
                        isLoading: false,
                        shopSummary: output.shopSummary!,
                    });
                })
                .catch((err) => {
                    this.setState({
                        error: err.message,
                    });
                    localStorage.removeItem(Storage.ShopID);
                });
        });
    };

    registerAccount = () => {
        this.props.auth
            .currentSession()
            .then((session) => {
                const token = session.getIdToken().getJwtToken();
                this.props.registerShopAPI
                    .RegisterAccount({
                        identityToken: token,
                        shopID: localStorage.getItem(Storage.ShopID)!,
                        refreshURL:
                            window.location.protocol +
                            "//" +
                            window.location.host +
                            Path.ShopDashboard,
                        returnURL:
                            window.location.protocol +
                            "//" +
                            window.location.host +
                            Path.ShopDashboard,
                    })
                    .then((output) => {
                        if (output.statusCode === 403) {
                            return this.props.history.push(Path.Login);
                        }

                        if (output.statusCode === 200) {
                            return window.location.replace(output.accountLink);
                        }

                        this.setState({
                            error: "Sorry, we were unable to connect with our account partner Stripe. We are working to resolve the issue.",
                        });
                    })
                    .catch(() =>
                        this.setState({
                            error: "Sorry, we were unable to connect with our account partner Stripe. We are working to resolve the issue.",
                        })
                    );
            })
            .catch(() => {
                this.props.history.push(Path.Login);
            });
    };

    navigate = (path: Path) => {
        this.props.history.push(path);
    };

    changeActivity = (activity: Activity) => {
        this.setState({
            activity: activity,
        })
    }

    updateLogo = (image: any) => {
        const { shopSummary } = this.state
        this.setState({
            shopSummary: {
                ...shopSummary,
                logo: image,
            }
        })
    }

    updateShopAlias = (alias: string) => {
        const { shopSummary } = this.state
        shopSummary.alias = alias
        this.setState({
            shopSummary: shopSummary
        })
    }

    updateShopContact = (contact: ContactDetails) => {
        const { shopSummary } = this.state
        shopSummary.email = contact.email
        shopSummary.phone = contact.phone
        shopSummary.whatsapp = contact.whatsapp
        this.setState({
            shopSummary: shopSummary
        })
    }

    render() {
        const props: ShopSummaryCardProps = {
            ...this.props,
            name: this.state.shopSummary.name,
            address: this.state.shopSummary.address,
            accountStatus: this.state.shopSummary.accountStatus,
            deliveryStatus: this.state.shopSummary.deliveryStatus,
            error: this.state.error,
            isLoading: this.state.isLoading,
            credit: this.state.shopSummary.credit,
            shopID: localStorage.getItem(Storage.ShopID) || "",
            logo: this.state.shopSummary.logo,
            activity: this.state.activity,
            shopAlias: this.state.shopSummary.alias,
            email: this.state.shopSummary.email,
            phone: this.state.shopSummary.phone,
            whatsapp: this.state.shopSummary.whatsapp || "-",
            actions: this.state.shopSummary.actions,
            updateLogo: this.updateLogo,
            registerAccount: this.registerAccount,
            navigate: this.navigate,
            changeActivity: this.changeActivity,
            updateAlias: this.updateShopAlias,
            updateContact: this.updateShopContact,
        };
        return <ShopSummaryCard {...props} />;
    }
}

export interface ShopSummaryCardProps extends RouteComponentProps {
    activity: Activity;
    name: string;
    address: string;
    accountStatus: string;
    deliveryStatus: string;
    credit: number;
    error: string;
    isLoading: boolean;
    shopID?: string;
    shopAlias: string;
    logo: string;
    email: string;
    phone: string;
    whatsapp: string;
    actions: ShopAction[];
    navigate: (path: Path) => void;
    registerAccount: () => void;
    changeActivity: (activity: Activity) => void;
    updateLogo: (image: any) => void;
    updateAlias: (alias: string) => void;
    updateContact: (contact: ContactDetails) => void;
}

export enum Activity {
    None,
    UpdateLogo,
    UpdateAlias,
    UpdateContact,
}

export const ShopSummaryCard: FunctionComponent<ShopSummaryCardProps> = (
    props
) => (
    <>
        <Modal
            show={props.activity == Activity.UpdateLogo}
            onHide={() => props.changeActivity(Activity.None)}
        >
            <Modal.Header>
                <Modal.Title>Update Logo</Modal.Title>
                <button type="button" className="close" aria-label="Close" onClick={() => props.changeActivity(Activity.None)}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                <UpdateLogo postSubmit={img => {
                    props.changeActivity(Activity.None)
                    props.updateLogo(img)
                }} imageStore={S3} fileReader={new FileReader()} />
            </Modal.Body>
        </Modal>

        <Modal
            show={props.activity == Activity.UpdateAlias}
            onHide={() => props.changeActivity(Activity.None)}
        >
            <Modal.Header>
                <Modal.Title>Change Shop Alias</Modal.Title>
                <button type="button" className="close" aria-label="Close" onClick={() => props.changeActivity(Activity.None)}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                <UpdateAlias {...props} updateAliasAPI={new UpdateShopAlias()} auth={Auth} postSubmit={alias => {
                    props.changeActivity(Activity.None)
                    props.updateAlias(alias)
                }} />
            </Modal.Body>
        </Modal>

        <Modal
            show={props.activity == Activity.UpdateContact}
            onHide={() => props.changeActivity(Activity.None)}
        >
            <Modal.Header>
                <Modal.Title>Change Shop Contact</Modal.Title>
                <button type="button" className="close" aria-label="Close" onClick={() => props.changeActivity(Activity.None)}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                <UpdateContact {...props} setShopContactDetailsAPI={new SetShopContactDetails()} auth={Auth} postSubmit={contact => {
                    props.updateContact(contact)
                    props.changeActivity(Activity.None)
                }} />
            </Modal.Body>
        </Modal>

        <Alert variant={"danger"} show={props.error != ""}>
            {props.error}
        </Alert>
        {props.isLoading ? (
            <div style={{ textAlign: "center" }}><Spinner variant="success" animation="border" /></div>
        ) : (
            <>
                {props.name && (
                    <Row>
                        <Col xs={12} style={{ marginBottom: "3rem" }}>
                            <h1>Dashboard</h1>
                            <Col xs={12} xl={6} xxl={4}>
                                <div style={{ width: "250px", textAlign: "center" }}>
                                    <Image style={{ display: "block", padding: "1rem" }} width="100%" src={props.logo || "https://via.placeholder.com/350x350.png?text=Logo"} rounded />
                                    <Button name={"Update Logo"} onClick={() => {
                                        props.changeActivity(Activity.UpdateLogo)
                                    }} variant={variant.Primary} />
                                </div>
                            </Col>
                        </Col>
                        <Col xs={12} xl={6} xxl={4}>
                            <Row>
                                <Col xs={12}>
                                    <h5>Summary</h5>
                                    <ListGroup as="ol" style={{ marginBottom: "2rem", borderTop: `1px solid ${Color.Grey}`, borderRadius: 0 }}>
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                            style={{ border: 0 }}
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Name</div>
                                                {props.name}
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                            style={{ border: 0 }}
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Alias</div>
                                                {props.shopAlias}
                                            </div>
                                            <a onClick={() => props.changeActivity(Activity.UpdateAlias)} style={{ color: Color.Primary, cursor: "pointer" }}>Edit</a>
                                        </ListGroup.Item>
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                            style={{ border: 0 }}
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Site</div>
                                                https://shops.{process.env.REACT_APP_BASE_DOMAIN}/shop/{(props.shopAlias !== "") ? props.shopAlias : props.shopID}
                                            </div>
                                            <a onClick={() => navigator.clipboard.writeText(`https://shops.${process.env.REACT_APP_BASE_DOMAIN}/shop/${(props.shopAlias !== "") ? props.shopAlias : props.shopID}`)} style={{ color: Color.Primary, cursor: "pointer" }}>Copy</a>
                                        </ListGroup.Item>
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                            style={{ border: 0 }}
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Address</div>
                                                {props.address}
                                            </div>
                                        </ListGroup.Item>
                                    </ListGroup>
                                    <h5>Contact</h5>
                                    <ListGroup as="ol" style={{ marginBottom: "2rem", borderTop: `1px solid ${Color.Grey}`, borderRadius: 0 }}>
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                            style={{ border: 0 }}
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Email</div>
                                                {props.email}
                                            </div>
                                            <a onClick={() => props.changeActivity(Activity.UpdateContact)} style={{ color: Color.Primary, cursor: "pointer" }}>Edit</a>
                                        </ListGroup.Item>
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                            style={{ border: 0 }}
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Phone</div>
                                                {props.phone}
                                            </div>
                                            <a onClick={() => props.changeActivity(Activity.UpdateContact)} style={{ color: Color.Primary, cursor: "pointer" }}>Edit</a>
                                        </ListGroup.Item>
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                            style={{ border: 0 }}
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">WhatApp Number</div>
                                                {props.whatsapp}
                                            </div>
                                            <a onClick={() => props.changeActivity(Activity.UpdateContact)} style={{ color: Color.Primary, cursor: "pointer" }}>Edit</a>
                                        </ListGroup.Item>
                                    </ListGroup>

                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} xl={6} xxl={4}>
                            <Row>
                                <Col>
                                    <h5>Platform</h5>
                                    <ListGroup as="ol" style={{ marginBottom: "2rem", borderTop: `1px solid ${Color.Grey}`, borderRadius: 0 }}>
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                            style={{ border: 0 }}
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Credit</div>
                                                £{(props.credit) ? props.credit.toFixed(2) : 0}
                                            </div>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )}
                <Actions {...props} />
            </>
        )}
    </>
);

export const Actions: FunctionComponent<ShopSummaryCardProps> = (props) => (
    <div style={{ marginTop: "1rem" }}>
        {(props.name == "" || props.address == "" || props.accountStatus != "OK" || props.deliveryStatus != "OK") && (
            <p style={{ textAlign: "center", marginTop: "1rem" }}>Required Actions</p>
        )}
        {props.name == "" && (
            <Card>
                <Card.Body>
                    <Card.Text>Please register your shop name.</Card.Text>
                    <Button
                        variant={variant.Primary}
                        id="register-name-btn"
                        name={"Register"}
                        onClick={() => props.navigate(Path.RegisterShopName)}
                    />
                </Card.Body>
            </Card>
        )}
        {props.address === "" && (
            <Card>
                <Card.Body>
                    <Card.Text>Please register your shop address.</Card.Text>
                    <Button
                        variant={variant.Primary}
                        id="register-address-btn"
                        name={"Register"}
                        onClick={() => props.navigate(Path.RegisterShopAddress)}
                    />
                </Card.Body>
            </Card>
        )}
        {props.accountStatus.toUpperCase() !== "OK" && (
            <Card>
                <Card.Body>
                    <Card.Text>Our payment partner is currently reviewing your account details which should only take a couple of days. Meanwhile, you can view or edit your account details by selecting the button below. Please ensure all required details filled in correctly.</Card.Text>
                    <Button
                        variant={variant.Primary}
                        id="account-details-btn"
                        name={"Account Details"}
                        onClick={() => props.registerAccount()}
                    />
                </Card.Body>
            </Card>
        )}
        {containsAction("ZettleAuthExpired", props.actions) && (
            <Card className="elevate">
                <Card.Body>
                    <Card.Text>It appears that your Zettle integration for product synchronization has expired. To continue enjoying automatic product synchronisation, please log in to your Zettle account and re-authenticate the integration at your earliest convenience.</Card.Text>
                    <Button
                        variant={variant.Primary}
                        id="zettle-auth-btn"
                        name={"Go"}
                        onClick={() => props.navigate(Path.Integrations)}
                    />
                </Card.Body>
            </Card>
        )}
    </div>
);

function containsAction(type: string, actions: ShopAction[]): boolean {
    const result = actions.filter(action => action.id === type)
    return result && result.length > 0
}
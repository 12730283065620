export enum Storage {
    ShopName = "ShopName",
    ShopID = "ShopID",
    ShopAddressID = "ShopAddressID",
    IsLoggedIn = "IsLoggedIn",
    Referral = "Referral",
}

export enum Path {
    Home = "/",
    TermsAndConditions = "/terms-and-conditions",
    Privacy = "/privacy",
    Contact = "/contact",
    ShopDashboard = "/dashboard",
    RegisterMember = "/register/member",
    RegisterMemberConfirmation = "/register/member/confirmation",
    RegisterShopAddress = "/register/shop/address",
    RegisterShopName = "/register/shop/name",
    RegisterShop = "/register/shop",
    RegisterShopContact = "/register/shop/contact",
    RegisterShopAccount = "/register/shop/account",
    Login = "/login",
    Products = "/products",
    Customers = "/customers",
    AddProduct = "/products/add",
    Delivery = "/delivery",
    Orders = "/orders",
    Stock = "/stock",
    Integrations = "/integrations",
    IntegrationsZettle = "/integrations/zettle",
    Payments = "/payments",
    ResetPassword = "/reset-password",
    AddShop = "/add-shop",
    Referral = "/referral"
}

export const Color = {
    Primary: `#${process.env.REACT_APP_COLOR_PRIMARY}` || "#5b5bff",
    Black: "#000000",
    Brown: "#552200",
    DarkGrey: "#3d3d3d",
    Grey: "#C0C0C0",
    LightGrey: "#F8F8FA",
    White: "#FFFFFF",
    Red: "#E82064",
}

export const Sizes = {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    xxl: '1536px',
};

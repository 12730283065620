import { Storage as S3 } from "aws-amplify";
import { Component } from "react";
import { Storage } from "../../env";
import { Alert, Form } from "react-bootstrap";
import { Button, variant } from "../form/Button";
import { v4 as uuid } from 'uuid';
import { read } from "fs";

export interface UpdateLogoState {
    fields: Map<formAttribute, any>;
    error: string;
}

export interface UpdateLogoProps {
    imageStore: typeof S3;
    fileReader: FileReader;
    postSubmit: (image: any) => void;
}

export enum formAttribute {
    image = "image",
}

export default class UpdateLogo extends Component<
    UpdateLogoProps,
    UpdateLogoState
> {
    constructor(props: UpdateLogoProps) {
        super(props);
        this.state = {
            fields: new Map(),
            error: "",
        };
    }

    handleChange = (name: formAttribute, value: any) => {
        const fields = this.state.fields;
        fields.set(name, value);
        this.setState({
            fields: fields,
        });
    };

    isValidField = (
        field: formAttribute,
        fields: Map<formAttribute, any>
    ): boolean => {
        return fields.has(field);
    };

    handleSubmit = async () => {
        if (!this.isValidField(formAttribute.image, this.state.fields)) {
            const error = "You must upload an image before submission.";
            return this.setState({
                error: error,
            });
        }

        const shopID = localStorage.getItem(Storage.ShopID);
        if (shopID == null) {
            return this.setState({
                error: "Shop could not be found. Please navigate back to the shop dashboard to resync",
            });
        }

        const imageName = `${localStorage.getItem(Storage.ShopID)}/logo/${uuid()}`

        const file = this.state.fields.get(formAttribute.image)
        this.props.fileReader.readAsDataURL(file)

        await this.props.imageStore.put(
            imageName,
            this.state.fields.get(formAttribute.image),
            {
                contentType: "image/jpeg",
            }
        );

        this.props.postSubmit(this.props.fileReader.result)
    }

    render() {
        const props: UpdateLogoFormProps = {
            image: this.state.fields.get(formAttribute.image),
            error: this.state.error,
            onChange: this.handleChange,
            onSubmit: this.handleSubmit,
        };
        return <UpdateLogoForm {...props} />;
    }
}

export interface UpdateLogoFormProps {
    image?: File;
    error: string;
    onChange: (field: formAttribute, name: any) => void;
    onSubmit: () => void;
}

export interface UpdateLogoFormState {
    ImageName: string;
}

export class UpdateLogoForm extends Component<
    UpdateLogoFormProps,
    UpdateLogoFormState
> {
    constructor(props: UpdateLogoFormProps) {
        super(props);
        this.state = {
            ImageName: "Select Image",
        };
    }

    render() {
        return (
            <Form>
                <Alert variant={"danger"} show={this.props.error != ""}>
                    {this.props.error}
                </Alert>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text">Image</span>
                    </div>
                    <div className="custom-file">
                        <input
                            type="file"
                            className="custom-file-input"
                            id="inputGroupFile01"
                            accept="image/jpeg"
                            style={{
                                display: "block",
                            }}
                            onChange={(e) => {
                                this.props.onChange(
                                    formAttribute.image,
                                    e.target.files![0]
                                );
                                this.setState({
                                    ImageName: e.target.files![0].name,
                                });
                            }}
                        />
                        <label className="custom-file-label">
                            {this.state.ImageName}
                        </label>
                    </div>
                </div>
                <Button style={{ float: "right" }} variant={variant.Primary} name={"Submit"} onClick={() => this.props.onSubmit()} />
            </Form>
        );
    }
}